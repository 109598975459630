const mapMarkers = {
  // location svg pins
  'Actively Selling' : '<svg class="icon-marker active actively" data-term="Actively Selling" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-current"></use></svg>',
  'Coming Soon'      : '<svg class="icon-marker active soon" data-term="Coming Soon" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-soon"></use></svg>',
  'Sold Out'         : '<svg class="icon-marker active sold" data-term="Sold Out" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-sold"></use></svg>',
  // community svg pins
  'entertainment'    : '<svg class="icon-marker active entertainment" data-term="entertainment" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-entertainment"></use></svg>',
  'fitness'          : '<svg class="icon-marker active fitness" data-term="fitness" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-fitness"></use></svg>',
  'medical'          : '<svg class="icon-marker active medical" data-term="medical" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-medical"></use></svg>',
  'parks'            : '<svg class="icon-marker active parks" data-term="parks" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-parks"></use></svg>',
  'restaurants'      : '<svg class="icon-marker active restaurants" data-term="restaurants" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-restaurants"></use></svg>',
  'services'         : '<svg class="icon-marker active services" data-term="services" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-services"></use></svg>',
  'transportation'   : '<svg class="icon-marker active transportation" data-term="transportation" xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40"><use href="#icon-map-marker-transportation"></use></svg>',
};

export default mapMarkers;
