import mapMarkers from "./mapMarkers";
import mapPopup from "./mapPopup";

const moduleMap = (section) => {
  if (!section) return;
  let mapLat = section.getAttribute('data-lat'),
    mapLng = section.getAttribute('data-lng'),
    mapAddress = section.getAttribute('data-address'),
    mapZoom = section.getAttribute('data-zoom'),
    mapID = section.getAttribute('data-mapId'),
    dataPostsInfo = section.getAttribute('data-all-posts-info'),
    dataFirstState = section.getAttribute('data-first-state'),
    dataTermState = section.getAttribute('data-term-state');

  async function initMap(zoom, mapAddress, pxLat, pxLng, mapID, mapBlock, dataPostsInfo, dataFirstState, dataTermState) {
    // Import library
    const {Map} = await google.maps.importLibrary("maps");
    const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
    // Parser
    const parser = new DOMParser();

    // Main position
    let myLatLng = {lat: Number(pxLat), lng: Number(pxLng)};
    // Map options
    let mapOptions = {
      zoom: Number(zoom),
      center: myLatLng,
      disableDefaultUI: true,
      mapId: mapID,
    };
    // Map
    const map = new Map(mapBlock, mapOptions);

    // Close info window when already created info window or not click another marker
    let infoWindow = false;
    mapBlock.addEventListener('click', (e) => {
      if(!infoWindow) return;
      if(!e.target.closest('.location-map-popup') && !e.target.closest('.icon-marker')) {
        infoWindow.close();
      }
    })

    // Variable need for state filter function
    let arrSVG = [];
    // Json parse and geocode
    if (dataPostsInfo) {
      // Json parse arr posts info
      const postsArr = JSON.parse(dataPostsInfo);
      // Array markers and info
      postsArr.forEach((item) => {
        const location = item.address1 ? new google.maps.LatLng(item.lat, item.lng) : new google.maps.LatLng(item.cords.lat, item.cords.lng);
        const formated_address = item.address1 ? item.address1 + ' ' + item.address2 : item.cords.formated_address;

        // Parse from string in svg
        const pinSvg = parser.parseFromString(
            mapMarkers[item.term],
            "image/svg+xml",
        ).documentElement;

        // Add all svg in arr for filter function
        arrSVG.push(pinSvg);

        // Term is not displayed at the beginning
        if(dataFirstState?.split(', ').includes(pinSvg.getAttribute('data-term'))) {
          pinSvg.classList.remove('active');
        }

        // Create marker
        const titleMarker = item.address1
            ? `${item.post_title} - ${item.area} - ${item.address1} - ${item.address2}`
            : `${item.post_title || item.term.toUpperCase()} - ${item.address}`;
        const marker = new AdvancedMarkerElement({
          map,
          position: location,
          content: pinSvg,
          title: titleMarker,
        })

        // Create info window for posts
        // On click the marker create info window
        marker.addListener('click', function () {
          // Close info window when already created
          if(infoWindow !== false) infoWindow.close();

          // Create info window
          infoWindow = new google.maps.InfoWindow({
            maxWidth: 340,
            ariaLabel: item.post_title,
            headerDisabled: true,
            content: mapPopup(item, formated_address),
          });

          // Open info window on click
          infoWindow.open({
            anchor: marker,
            map,
          });
        })
      })
    }

    // Filter function work after load map
    google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
      // State
      const state = {
        block: document.querySelector('.section-map'),
        activeTerms: [],
        barFilterButtons: null,
        wrapFilterButtons: null,
        allButtons: [],
        allSVGs: [],
        allPosts: [],
        activeTermButtons: [],
      };

      // Update state
      if(state.block) {
        state.barFilterButtons = state.block?.querySelector('#wrap-filter-buttons');
        state.wrapFilterButtons = state.block?.querySelector('.filter-buttons__filter--wrap');
        state.allButtons = [...state.block?.querySelectorAll('.filter-button')];
        state.allSVGs = arrSVG;
        state.allPosts = [...state.block?.querySelectorAll('.find-your-home-map__item')];
        if(dataTermState) {
          state.activeTermButtons = JSON.parse(dataTermState);
        } else {
          state.activeTermButtons = state.allButtons.filter(btn => btn.classList.contains('active')).map(btn => btn.getAttribute('data-term'));
        }
      }

      // Function for update state after click filter buttons
      const updateClasses = (arr, activeTerms, addClass = true) => {
        if(arr) {
          arr.forEach((item) => {
            addClass ? item.classList.add('active') : item.classList.remove('active');
            if(activeTerms) {
              activeTerms.includes(item.getAttribute('data-term')) ? item.classList.add('active') : item.classList.remove('active');
            }
          })
        }
      };

      // Function clearFilter not active all btn and show all posts and svg
      const clearFilter = () => {
        updateClasses(state.allButtons, false, false);
        updateClasses(state.allSVGs);
        updateClasses(state.allPosts);
        state.activeTermButtons = [];
      };

      // Event click on filter btn
      state.barFilterButtons?.addEventListener('click', function(e) {
        const currentButton = e.target.closest('.filter-button');
        const clearBTN = e.target.closest('button[data-clear-term]');
        const filterMobileButton = e.target.closest('.filter-buttons__filter--btn');

        if (currentButton) {
          currentButton.classList.toggle('active');
          const dataTerm = currentButton.getAttribute('data-term');

          if(dataTerm) {
            if(currentButton.classList.contains('active')) {
              state.activeTermButtons.push(dataTerm);
            } else {
              state.activeTermButtons = state.activeTermButtons.filter(item => !item.includes(dataTerm));
            }
          }

          updateClasses(state.allSVGs, state.activeTermButtons);
          updateClasses(state.allPosts, state.activeTermButtons);

          if (!state.activeTermButtons.length ) clearFilter();
        }

        if (clearBTN) clearFilter();

        if (filterMobileButton) state.wrapFilterButtons.classList.toggle('active');
      });
    });

  }

  initMap(mapZoom, mapAddress, mapLat, mapLng, mapID, section, dataPostsInfo, dataFirstState, dataTermState).then(() => {
    console.log("Map initialized successfully.");
  });
}

export default moduleMap;
