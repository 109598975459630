function mapPopup ({image, post_title, area, address, address1, address2, units, price, link}, addressGeo) {
  return `<div id="locationPopup" class="location-map-popup">
            <div id="bodyLocationPopup" class="location-map-popup__content">
              ${ image ? `<img class="location-map-popup__image" src="${image}" alt="${ post_title ?? `title` }">` : '' }
              ${ post_title ? `<p class="location-map-popup__title">${post_title}</p>` : '' }
              ${ area ? `<p class="location-map-popup__area">${area}</p>` : '' }
              ${ address ? `<p class="location-map-popup__address">${addressGeo}</p>` : '' }
              ${ address1 ? `<p class="location-map-popup__address1">${address1}</p>` : '' }
              ${ address2 ? `<p class="location-map-popup__address2">${address2}</p>` : '' }
              ${ units ? `<p class="location-map-popup__units">${units}</p>` : '' }
              ${ price ? `<p class="location-map-popup__price">${price}</p>` : '' }
              ${ link ? `<a class="location-map-popup__link" href="${link}" target="_blank" title="${ post_title ?? `title` }">view community</a>` : '' }
            </div>
          </div>`;
}

export default mapPopup;
